import img5 from "../assets/tmp/img_5.webp";
import img6 from "../assets/tmp/img_6.webp";
import img7 from "../assets/tmp/img_7.webp";


// Are you TheRoot content

export const areYouTheRootLabel = 'TheRoot C&C Hub';
export const areYouTheRootTitle = 'TheRoot C&C Hub, Pioneering the Future of Control Centers!';
export const areYouTheRootSubtitle = 'Say Farewell to IT Hassles: Liberating You from Daily Tech Hurdles, theRooT Empowers a Strategic Shift in Your Business Operations. Unleash the Game-Changer!';

export const areYouTheRootContent = [
  {
    label: 'Introducing TheRoot!',
    title: 'Your Ultimate Project Management Solution!',
    description: 'Say goodbye to the hassle of burdening your system administrators with intricate project role assignments. With TheRoot, we empower project managers by streamlining and automating the process of managing project roles, freeing up valuable time for your IT professionals to focus on more strategic initiatives.',
    content: [
      {
        faIcon: 'fa fa-rocket',
        name: 'Streamline Your Workflow:',
        desc: 'In essence, TheRoot is not just a tool; it is a strategic asset for your organization. By delegating project role management to project managers, you empower them to take ownership and drive successful project outcomes. Experience the future of project management with TheRoot – where efficiency, collaboration, and security converge seamlessly.',
        image: img5
      },
      {
        faIcon: 'fa fa-cog',
        name: ' Take Swift Action:',
        desc: 'We understand that the success of your projects relies on your ability to take swift, informed actions. TheRoot equips you with powerful tools to make decisions on-the-fly. Whether it is reassigning tasks, updating project statuses, or sending notifications to team members, our platform ensures you can act decisively to keep your projects on track.',
        image: img5
      },
      {
        faIcon: 'fa fa-usd',
        name: ' Project management excellence:',
        desc: 'Do not let project management challenges hold you back any longer. Join the ranks of successful project managers who have chosen TheRoot to simplify their workflows, increase productivity, and deliver exceptional results.Your projects deserve the best, and that is exactly what TheRoot delivers. Get started now and watch your project management soar to new heights.',
        image: img5
      }
    ]
  },
  {
    label: 'Seamlessly Embrace',
    title: ' Your Ultimate IT Administration Solution!',
    description: 'Are you grappling with the complexity of managing multiple projects and company members within your organization? Say goodbye to the headaches of administrative chaos and say hello to TheRoot, the state-of-the-art Software as a Service (SaaS) that redefines IT administration.',
    content: [
      {
        faIcon: 'fa fa-refresh',
        name: 'Simplify Your Administrative Workflow:',
        desc: 'With TheRoot, IT administrators can seamlessly find, view, and assign company members assignments across various projects within your organisation. No more tedious manual processes or scattered information. TheRoot centralises your administrative tasks, making your job a breeze!',
        image: img6
      },
      {
        faIcon: 'fa fa-user',
        name: 'Take Swift and Informed Actions:',
        desc: 'In the world of IT administration, efficiency is paramount. TheRoot empowers you to take swift and informed actions with ease. Whether it is assigning roles, reviewing assignments, or managing user access, our platform ensures you can handle it all effortlessly to maintain streamlined IT operations.',
        image: img6
      },
      {
        faIcon: 'fa fa-line-chart',
        name: 'Do not let IT administrative complexities slow you down:',
        desc: 'Join the ranks of successful IT administrators who have chosen TheRoot to simplify their workflows, increase productivity, and maintain a well-organized IT environment. Try TheRoot today and experience a new era of IT administration excellence! Your organization deserves the best, and TheRoot delivers precisely that. Get started now and watch your IT administration become a strategic asset for your organization.',
        image: img6
      }
    ]
  },
  {
    label: 'Extend Security',
    title: 'Your Ultimate Security Solution!',
    description: 'Are you concerned about the alignment of your company security assignments with its stringent policies and practices? Fear not! TheRoot is here to empower your information security staff with an intuitive Software as a Service (SaaS) solution, designed to simplify security audits.',
    content: [
      {
        faIcon: 'fa fa-cloud-upload',
        name: 'Effortless Security Auditing:',
        desc: 'With TheRoot, information security professionals can manually and easily find, view, and assess company members assignments across various projects within your organisation. Bid farewell to complex and time-consuming audits – our platform centralizes your security oversight, making compliance a breeze!',
        image: img7
      },
      {
        faIcon: 'fa fa-bell-o',
        name: 'Verify Policy Alignment:',
        desc: 'TheRoot puts the power in your hands to ensure that every security assignment is in perfect alignment with your company critical security policies and practices. No more guesswork or manual tracking – our platform helps you identify inconsistencies and take prompt corrective actions.',
        image: img7
      },
      {
        faIcon: 'fa fa-refresh',
        name: 'Do not leave your security to chance! ',
        desc: 'Join the ranks of successful information security professionals who have chosen TheRoot to simplify their security audits, improve compliance accuracy, and fortify their organization defenses. Try TheRoot today and experience a new era of information security assurance excellence! Your organization security deserves the utmost attention, and TheRoot delivers precisely that. Get started now and ensure that your security assignments are always aligned with your company policies and practices, giving you peace of mind in an ever-evolving digital landscape.',
        image: img7
      }
    ]
  }
];
