import iconDiscord from "../assets/icons/socials/discord.svg";
import iconGithub from "../assets/icons/socials/github-black.svg";
import iconLinkedin from "../assets/icons/socials/linkedin.svg";
import iconTwitter from "../assets/icons/socials/twitter.svg";


// About Page content

export const aboutTitle = 'TheRoot Command&Control center from the future.';

export const aboutSubtitle = [
  'Welcome to TheRoot, where we invite you to witness the future unfold. Immerse yourself in Tomorrow\'s Mastery with our groundbreaking Command&Control Hub, redefining the very essence of control centers. We are at the forefront of innovation, pioneering a new era where seamless integration, cutting-edge technology, and unparalleled efficiency converge.',
  'At TheRoot, we don\'t just embrace the future; we shape it. Our Command&Control Hub stands as a testament to our commitment to pushing boundaries and reimagining what\'s possible. Step into a realm where precision meets intuition, where advanced capabilities seamlessly blend with user-friendly design.',
  'Discover a world where every click, every command, propels you into a new dimension of control. TheRoot Command&Control Hub isn\'t just a platform; it\'s an experience designed to anticipate your needs, streamline operations, and elevate your control center to heights previously unexplored.',
  'Join us on this journey into the future, where mastery is not just a goal but a daily reality. TheRoot Command&Control Hub — Pioneering the Future of Control Centers, Today.'
];

export const aboutSocials = [
  {
    icon: iconGithub,
    name: 'GitHub',
    link: 'https://github.com/therootapp'
  },
  {
    icon: iconLinkedin,
    name: 'Linkedin',
    link: 'https://www.linkedin.com/in/the-root-app'
  },
  {
    icon: iconTwitter,
    name: 'Twitter',
    link: 'https://twitter.com/theroot_app'
  }
];
