import React from 'react';
import {Container} from "react-bootstrap";

const PrivacyPolicyPage = () => {
  return (
    <Container className="default-page terms-page">

      <h1 className="page-title terms__page-title">
        Privacy Policy for TheRootApp
      </h1>
      <p className="terms__date">
        Last updated: [24.01.2024] <i>update pending</i>
      </p>

      <p className="terms__partition">
        Thank you for choosing TheRootApp ("we," "us," or "our"). This Privacy Policy is meant to help you understand how we collect, use, and safeguard your personal information in connection with your use of our Software as a Service (SaaS) solution.
      </p>

      <div className="terms__chapter">
        1. Information We Collect:
      </div>
      <p className="terms__partition">
        <strong>1.1 User Credentials:</strong><br/>
        We collect and store the user's email address and password solely for the purpose of registration, authenticating and authorizing access to our SaaS solution.
      </p>
      <p className="terms__partition">
        <strong>1.2 OAuth Authentication:</strong><br/>
        To enhance security, we use OAuth for authentication. We do not store any sensitive information obtained during the OAuth process, such as tokens or passwords.
      </p>

      <div className="terms__chapter">
        2. How We Use Your Information:
      </div>
      <p className="terms__partition">
        <strong>2.1 User Authentication:</strong><br/>
        We use the collected email and password solely for user authentication and authorization within our SaaS solution.
      </p>
      <p className="terms__partition">
        <strong>2.2 Communication with Third-Party Systems:</strong><br/>
        Our SaaS solution communicates with third-party systems via APIs for specific functionalities. We do not store any data received from these third-party systems. Our interaction with third-party systems is limited to processing requests initiated by the user.
      </p>

      <div className="terms__chapter">
        3. Third-Party Systems:
      </div>
      <p className="terms__partition">
        <strong>3.1 API Communication:</strong><br/>
        When our SaaS solution interacts with third-party systems, it does so using secure APIs. We only transmit the necessary information required to fulfill user-initiated requests.
      </p>
      <p className="terms__partition">
        <strong>3.2 No Storage of Third-Party Data:</strong><br/>
        We do not store any data obtained from third-party systems. All data processing is transient and occurs in real-time to provide the requested functionalities.
      </p>

      <div className="terms__chapter">
        4. Security Measures:
      </div>
      <p className="terms__partition">
        <strong>4.1 Encryption:</strong><br/>
        All data transmission, including user credentials and communication with third-party systems, is encrypted using industry-standard protocols to ensure the confidentiality and integrity of the information.
      </p>
      <p className="terms__partition">
        <strong>4.2 Access Controls:</strong><br/>
        We implement strict access controls to limit access to user data within our organization. Only authorized personnel with a legitimate need for access have permission to handle such data.
      </p>

      <div className="terms__chapter">
        5. User Rights:
      </div>
      <p className="terms__partition">
        <strong>5.1 Access and Correction:</strong><br/>
        Users have the right to access and correct their personal information. They can update their profile information directly within the SaaS solution.
      </p>
      <p className="terms__partition">
        <strong>5.2 Data Deletion:</strong><br/>
        Users can request the deletion of their account, which will result in the removal of their stored email and password information.
      </p>

      <div className="terms__chapter">
        6. Changes to the Privacy Policy:
      </div>
      <p className="terms__partition">
        <strong>6.1 Notification of Changes:</strong><br/>
        We reserve the right to update this Privacy Policy as necessary. Any changes will be communicated through our platform or via email.
      </p>

      <div className="terms__chapter">
        7. Contact Us:
      </div>
      <p className="terms__partition">
        <strong>7.1 Questions or Concerns:</strong><br/>
        If you have any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:contact@theroot.app" className="terms__link">contact@theroot.app</a> .
      </p>

      <p className="terms__partition mt-5">
        By using our SaaS solution, you agree to the terms outlined in this Privacy Policy.
      </p>

      <p className="terms__partition mt-5">
        [TheRootApp on behalf of Applied Reverse Engineering SL]<br/>
        [Monasterio de Irache, 41, 7A,<br/>
        Pamplona, Navarra, 31011, Spain]<br/>
        [<a href="mailto:contact@theroot.app" className="terms__link">contact@theroot.app</a>]
      </p>

    </Container>
  );
};

export default PrivacyPolicyPage;
