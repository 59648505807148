import group1 from "../assets/tmp/group_1.png";
import group2 from "../assets/tmp/group_2.png";
import group3 from "../assets/tmp/group_3.png";


// Home Page content

export const homePageLabel = 'TheRoot C&C';
export const homePageTitle = 'No more intricate technical jargon or time-consuming processes';
export const homePageSubtitle = 'One of the key advantages of our software is its intuitive user interface, making it a breeze for project managers, IT admins and security staff to assign and modify roles with just a few clicks. Our solution enhances collaboration and communication among team members, ensuring everyone is on the same page regarding their roles and responsibilities.';

export const homePageContent = [
  {
    label: 'Revolutionary Thinking',
    title: 'Revolutionize IT, Elevate Business: TheRoot Unlocks Your Potential',
    description: 'Discover a transformative solution that revolutionizes your IT landscape – TheRoot. Say goodbye to the daily grind of technical challenges and embrace a strategic shift that propels your business to new heights.',
    content: [
      {
        faIcon: 'tr tr-icon-monitoring',
        name: 'Streamlined Operations:',
        desc: 'Entrust managerial responsibilities to experts, enabling your IT staff to focus on core business tasks, ensuring seamless day-to-day operations.',
        image: group1
      },
      {
        faIcon: 'tr tr-icon-fa_chain-broken',
        name: ' Innovation Unleashed:',
        desc: 'Liberate your IT team from mundane tasks, empowering them to pioneer breakthrough technologies that fuel your competitive edge.',
        image: group1
      },
      {
        faIcon: 'tr tr-icon-attach_money',
        name: 'Cost Optimization:',
        desc: 'Our strategic approach identifies cost-saving opportunities, maximizing the impact of your IT budget.',
        image: group1
      }
    ]
  },
  {
    label: 'Seamlessly Embrace',
    title: 'Strategic Planning:',
    description: 'Foster a long-term vision, aligning IT initiatives with your business goals for sustainable success.',
    content: [
      {
        faIcon: 'tr tr-icon-stacked_line_chart',
        name: ' Enhanced Efficiency:',
        desc: 'Embrace optimized workflows and processes that streamline communication, project execution, and decision-making, driving efficiency across your organization.',
        image: group2
      },
      {
        faIcon: 'tr tr-icon-badge',
        name: 'Empowered Employees:',
        desc: ' Elevate your IT staff to influential roles, fostering empowerment, higher morale, and increased retention rates.',
        image: group2
      },
      {
        faIcon: 'tr tr-icon-add_shopping_cart',
        name: 'Elevate Your Business Potential:',
        desc: 'TheRoot unleashes innovation, empowers your IT team, and aligns strategies with your long-term vision. Seamlessly streamline operations, optimize costs, and fortify security. Embrace the strategic shift that is driving a new era of success.',
        image: group2
      }
    ]
  },
  {
    label: 'Extend seamlessly',
    title: 'Expand or incorporate seamlessly with your preferred tools.',
    description: 'Link up your preferred tools effortlessly with TheRoot, either by extending their capabilities or integrating them seamlessly. This not only streamlines your workflow but also opens up a world of limitless possibilities.',
    content: [
      {
        faIcon: 'tr tr-icon-terminal',
        name: 'We start with Jira and more tools are on the way.',
        desc: 'Embark on a journey with Jira as your launchpad, and anticipate the arrival of a suite of additional tools that will elevate your experience even further. The adventure is just beginning!',
        image: group3
      },
      {
        faIcon: 'tr tr-icon-account_tree',
        name: 'Seamless platform for swift, efficient operations.',
        desc: ' Simplify your workflow, enhance project management, and bolster security with our user-friendly and dynamic solution. Elevate your capabilities and empower your team to achieve more in less time.',
        image: group3
      },
      {
        faIcon: 'tr tr-icon-calendar_clock',
        name: 'It is time to break free from the daily grind of technical challenges.',
        desc: 'Escape the confines of the daily IT grind! Embrace a transformative shift that propels your business to new heights.',
        image: group3
      }
    ]
  }
];

export const homePageAddLabel1 = 'Unveiling Beyond';
export const homePageAddTitle1 = 'Explore the Extraordinary.';
export const homePageAddSubtitle1 = 'Discover New Dimensions with Exciting Integrations – Because That\'s Not All, There\'s More to Embrace!';

export const homePageAddLabel2 = 'Take-off faster';
export const homePageAddTitle2 = 'Seamless launch into unparalleled efficiency and productivity';
export const homePageAddSubtitle2 = 'Elevate Your Productivity, Safeguard Your Privacy, and Optimize Your Workflow: TheRoot is Designed to Propel You Toward Effortless Goal Attainment.';

export const homePageAdditionalContent_grid3 = [
  {
    faIcon: 'tr tr-icon-stars',
    title: 'Personalize Your Experience, Own It for Eternity',
    description: 'Shape Your Experience with TheRoot. Sculpt Your Workspace for Intuitive, Efficient, and Effortless Operation."'
  },
  {
    faIcon: 'tr tr-icon-school',
    title: 'Grasp Once, Recall Endlessly: Absorb Knowledge, Keep It Forever!',
    description: 'Effortless Takeoff, Swift Mastery: Seamlessly Navigate with Minimal Effort. Intuitive Design, Unforgettable Learning Experience.'
  },
  {
    faIcon: 'tr tr-icon-network_wifi_locked',
    title: 'Secure, by design and continuity',
    description: 'At TheRoot, prioritizing security is paramount, and the platform is meticulously designed with a focus on safeguarding your information. Regular security updates are implemented to ensure the highest level of protection for your data and privacy.'
  }
];
