import React, {FC} from 'react';
import {Link} from "react-router-dom";
import {Container} from "react-bootstrap";

import iconLogo from "../assets/logo_double_blue.svg";

import DefaultNavigation from "./DefaultNavigation";

const DefaultHeader: FC = () => {
  return (
    <header className="default-header">
      <Container>
        <div className="default-header__logo-wrapper">
          <Link to="/">
            <img src={iconLogo} alt="" className="default-header__logo-image"/>
          </Link>
        </div>

        <DefaultNavigation/>

        <div className="default-header__join-wrapper">
          <Link to="/pricing">
            <div className="btn btn-primary">
              <span>Get Started</span><i className="fa fa-long-arrow-right" aria-hidden="true" style={{marginLeft: '10px'}}/>
            </div>
          </Link>
        </div>
      </Container>
    </header>
  );
};

export default DefaultHeader;
