export const footerCommunity = [
  {
    name: 'GitHub',
    path: 'https://github.com/therootapp'
  },
  {
    name: 'LinkedIn',
    path: 'https://www.linkedin.com/in/the-root-app'
  },
  {
    name: 'Twitter',
    path: 'https://twitter.com/theroot_app'
  }
];
