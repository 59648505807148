import React from 'react';
import {Container} from "react-bootstrap";

const TermsNConditionsPage = () => {
  return (
    <Container className="default-page terms-page">

      <h1 className="page-title terms__page-title">
        Terms and Conditions for TheRootApp
      </h1>
      <p className="terms__date">
        Last updated: [24.01.2024] <i>update pending</i>
      </p>

      <p className="terms__partition">
        Thank you for choosing TheRootApp ("we," "us," or "our"). These Terms and Conditions govern your use of our
        Software as a Service (SaaS) solution. By using our service, you agree to abide by these terms.
      </p>

      <div className="terms__chapter">
        1. Account Registration:
      </div>
      <p className="terms__partition">
        <strong>1.1 User Credentials:</strong><br/>
        To use our SaaS solution, you must register an account. You agree to provide accurate and complete information
        during the registration process, including a valid email address and password.
      </p>
      <p className="terms__partition">
        <strong>1.2 Account Security:</strong><br/>
        You are responsible for maintaining the security of your account credentials. Any unauthorized use of your
        account must be reported to us immediately.
      </p>

      <div className="terms__chapter">
        2. Use of the SaaS Solution:
      </div>
      <p className="terms__partition">
        <strong>2.1 Permitted Use:</strong><br/>
        You agree to use our SaaS solution for its intended purpose, as described in our documentation. Any use that violates applicable laws or regulations is strictly prohibited.
      </p>
      <p className="terms__partition">
        <strong>2.2 Data Storage:</strong><br/>
        We only store user email addresses and passwords for authentication purposes. No data from third-party systems is stored within our solution.
      </p>
      <p className="terms__partition">
        <strong>2.3 Third-Party Payments:</strong><br/>
        Payments for our SaaS solution are handled by a third-party service. We do not store or process payment information directly. You are subject to the terms and conditions of the third-party payment service.
      </p>

      <div className="terms__chapter">
        3. Data Security:
      </div>
      <p className="terms__partition">
        <strong>3.1 Encryption:</strong><br/>
        We employ industry-standard encryption methods to secure the transmission and storage of user credentials. However, you acknowledge that no method of transmission over the internet or electronic storage is 100% secure.
      </p>
      <p className="terms__partition">
        <strong>3.2 No Third-Party Data Storage:</strong><br/>
        Our SaaS solution does not store any data obtained from third-party systems. We solely process requests initiated by users in real-time without retaining any information from external sources.
      </p>

      <div className="terms__chapter">
        4. Intellectual Property:
      </div>
      <p className="terms__partition">
        <strong>4.1 Ownership:</strong><br/>
        All intellectual property rights related to our SaaS solution, including but not limited to software, trademarks, and content, are owned by us.
      </p>
      <p className="terms__partition">
        <strong>4.2 License:</strong><br/>
        We grant you a limited, non-exclusive, non-transferable license to use our SaaS solution for its intended purpose. This license does not permit any resale or commercial use of our service.
      </p>

      <div className="terms__chapter">
        5. Termination:
      </div>
      <p className="terms__partition">
        <strong>5.1 Termination by User:</strong><br/>
        You may terminate your account at any time by following the provided instructions. Upon termination, your data will be deleted, and you will lose access to our SaaS solution.
      </p>
      <p className="terms__partition">
        <strong>5.2 Termination by Us:</strong><br/>
        We reserve the right to terminate your account or restrict access to our service if you violate these Terms and Conditions or engage in any activity that may harm our SaaS solution or other users.
      </p>

      <div className="terms__chapter">
        6. Changes to Terms and Conditions:
      </div>
      <p className="terms__partition">
        <strong>6.1 Notification of Changes:</strong><br/>
        We reserve the right to update these Terms and Conditions as necessary. Any changes will be communicated through our platform or via email.
      </p>

      <div className="terms__chapter">
        7. Contact Us:
      </div>
      <p className="terms__partition">
        <strong>7.1 Questions or Concerns:</strong><br/>
        If you have any questions or concerns regarding these Terms and Conditions, please contact us at [your contact email].
      </p>

      <p className="terms__partition mt-5">
        By using our SaaS solution, you agree to the terms outlined in these Terms and Conditions.
      </p>

      <p className="terms__partition mt-5">
        [TheRootApp on behalf of Applied Reverse Engineering SL]<br/>
        [Monasterio de Irache, 41, 7A,<br/>
        Pamplona, Navarra, 31011, Spain]<br/>
        [<a href="mailto:contact@theroot.app" className="terms__link">contact@theroot.app</a>]
      </p>

    </Container>
  );
};

export default TermsNConditionsPage;
